<template>
  <li class="vs-component vs-dropdown--item" @click="$emit('click')">
    <a class="vs-dropdown--item-link">
      <span class="flex items-center">
        <feather-icon :icon="icon" svg-classes="h-4 w-4" class="mr-2" />
        <span>{{ title }}</span>
      </span>
    </a>
  </li>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
};
</script>
