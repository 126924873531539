<template>
  <data-list-view-wrapper>
    <brand-sidebar
      v-if="$gate.can('create_brands') || $gate.can('edit_brands')"
      :is-sidebar-active="addNewDataSidebar"
      @close-sidebar="toggleDataSidebar"
      :data="sidebarData"
    />

    <vx-table
      ref="table"
      multiple
      v-model="selected"
      pagination
      :max-items="itemsPerPage"
      search
      :data="brands"
    >
      <div
        slot="header"
        class="flex flex-wrap-reverse items-center flex-grow justify-between"
      >
        <div class="flex flex-wrap-reverse items-center">
          <action-dropdown>
            <action-dropdown-item
              v-if="$gate.can('delete_brands')"
              icon="TrashIcon"
              @click="deleteSelected"
              title="Delete"
            />
          </action-dropdown>

          <button-create
            v-if="$gate.can('create_brands')"
            title="Add brand"
            @click="addNewData"
          />
        </div>

        <items-per-page
          :page="currentPage"
          :per-page="itemsPerPage"
          :total="brands.length"
          :queried-items="queriedItems"
          @change="changeItemsPerPage"
        />
      </div>

      <template slot="thead">
        <vs-th sort-key="name">Name</vs-th>
        <vs-th sort-key="description">description</vs-th>
        <vs-th>Action</vs-th>
      </template>

      <template slot-scope="{ data }">
        <tbody>
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td>
              <p class="product-name">{{ tr.name }}</p>
            </vs-td>

            <vs-td>
              <p class="product-description">{{ tr.description | title }}</p>
            </vs-td>

            <vs-td class="whitespace-no-wrap">
              <feather-icon
                v-if="$gate.can('edit_brands')"
                icon="EditIcon"
                svg-classes="w-5 h-5 hover:text-primary stroke-current"
                @click.stop="editData(tr)"
              />
              <feather-icon
                v-if="$gate.can('delete_brands')"
                icon="TrashIcon"
                svg-classes="w-5 h-5 hover:text-danger stroke-current"
                class="ml-2"
                @click.stop="confirmDelete(tr.id, tr.name)"
              />
            </vs-td>
          </vs-tr>
        </tbody>
      </template>
    </vx-table>
  </data-list-view-wrapper>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import VxTable from '@/components/vx-table/vxTable';
import DataListViewWrapper from '@/views/wrappers/DataListViewWrapper';
import BrandSidebar from '@/views/sidebars/BrandSidebar';
import ActionDropdown from '@/views/components/actions/ActionDropdown';
import ActionDropdownItem from '@/views/components/actions/ActionDropdownItem';
import ButtonCreate from '@/views/components/buttons/ButtonCreate';
import ItemsPerPage from '@/views/components/ItemsPerPage';

import { BrandAction } from '@/store/actionTypes';
import moduleBrand from '@/store/brands/moduleBrand';

import dataListMixin from '@/mixins/dataListMixin';

export default {
  mixins: [dataListMixin],
  components: {
    BrandSidebar,
    DataListViewWrapper,
    VxTable,
    ActionDropdown,
    ActionDropdownItem,
    ButtonCreate,
    ItemsPerPage,
  },
  computed: {
    ...mapState('brand', {
      brands: ({ brands }) => brands,
    }),

    queriedItems() {
      return this.$refs.table
        ? this.$refs.table.queriedResults.length
        : this.brands.length;
    },
  },
  methods: {
    ...mapActions('brand', [BrandAction.fetch, BrandAction.remove]),

    registerStoreModules() {
      if (!moduleBrand.isRegistered) {
        this.$store.registerModule('brand', moduleBrand);
        moduleBrand.isRegistered = true;
      }
    },
    addNewData() {
      if (this.$gate.can('create_brands')) {
        this.sidebarData = {};
        this.toggleDataSidebar(true);
      }
    },
    confirmDelete(id, name) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirm Delete',
        text: `Are you sure you want to delete "${name}"?`,
        accept: () => this.deleteData(id),
        acceptText: 'Delete',
      });
    },
    async deleteData(id) {
      if (this.$gate.can('delete_brands')) {
        this.$vs.loading();

        try {
          await this.removeBrand(id);

          this.notifySuccess(
            'Brand deleted',
            'The brand has been successfully deleted.'
          );
        } catch (error) {
          this.notifyError(error, 'Problem with deleting brands.');
        }

        this.$vs.loading.close();
      }
    },
    editData(data) {
      if (this.$gate.can('edit_brands')) {
        this.sidebarData = data;
        this.toggleDataSidebar(true);
      }
    },
    deleteSelected() {
      const ids = this.selected.map((item) => item.id);
      if (ids.length) this.confirmDeleteAll(ids);
    },
    confirmDeleteAll(ids) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirm Delete',
        text: `Are you sure you want to delete ${ids.length} selected brands?`,
        accept: () => this.deleteAll(ids),
        acceptText: 'Delete',
      });
    },
    async deleteAll(ids) {
      this.$vs.loading();

      try {
        await Promise.all(ids.map((id) => this.removeBrand(id)));

        this.notifySuccess(
          'Selected brands deleted',
          'Selected brands has been successfully deleted.'
        );
      } catch (error) {
        this.notifyError(error, 'Problem with deleting brands.');
      }

      this.$vs.loading.close();
      this.selected = [];
    },
  },
  async created() {
    this.registerStoreModules();

    this.$vs.loading();

    try {
      await this.fetchBrands();
    } catch (error) {
      this.notifyError(error, 'Problem with loading data.');
    }

    this.$vs.loading.close();
  },
};
</script>
