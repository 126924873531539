<template>
  <vs-dropdown class="dd-actions cursor-pointer">
    <div
      class="shadow-drop rounded-lg d-theme-dark-bg cursor-pointer flex items-center justify-center text-lg font-medium dropdown-button"
      :class="sizeClass"
    >
      <span class="title-text mr-2">{{ title }}</span>
      <feather-icon icon="ChevronDownIcon" svg-classes="h-4 w-4" />
    </div>

    <vs-dropdown-menu>
      <slot></slot>
    </vs-dropdown-menu>
  </vs-dropdown>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: false,
      default: 'Actions',
    },
    type: {
      type: String,
      required: false,
      default: '',
    },
    size: {
      type: [Number, String],
      required: false,
      default: 4,
    },
  },
  computed: {
    sizeClass() {
      return `p-${this.size}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.title-text {
  font-size: 1rem;
}

.dd-actions {
  margin-top: 1rem;

  @media (min-width: 320px) {
    margin-top: 0;
    margin-right: 1rem;
  }
}

.dropdown-button {
  background-color: #f8f6e8;
}
</style>
